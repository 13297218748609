import { DataGraphcms, UIText } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { KlappirButton } from '@klappir/ui/button';
import {
  SceneIllustration,
  StyledIllustration,
} from '@klappir/ui/illustration';
import { H2 } from '@klappir/ui/text';
import { getLink } from '@klappir/util/graphcms';
import { fonts, media, rem } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

const Title = styled(H2).attrs({ as: 'h2' })`
  margin: 0;
  width: 60%;
  margin: 0 auto;
  color: ${getColor('green')};
  font-weight: 700;
  div {
    width: 100%;
    clear: both;
    color: #000;
    font-weight: 700;
    ${media.md.below`font-size: 1.4em;`};
  }
  ${media.md.below`width: 100%; font-size: 1.5em;`};
`;

const Description = styled.div`
  padding: 1em 0 0;
  margin: 0 auto;
  width: 60%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;

  ${media.md.below`width: 100%;`};
`;

const StyledHero = styled.section`
  display: flex;
  flex-direction: column;
  padding: ${rem(32)} 8vw 0;
  text-align: center;

  margin-top: 60px;
  position: relative;

  ${StyledIllustration} {
    margin-top: -30px;
    margin-right: -8vw;
    max-height: ${rem(520)};
  }

  ${media.md.below`
   margin-top:4px;
   width:100%;
   padding:0px;
    ${StyledIllustration} {
      display: none;
    }
  `}

  ${media.xl.above`
    gap: 6%;
  `}
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  padding: 40px;
  position: relative;
  top: 40%;
  overflow: hidden;

  ${media.md.below`padding:8px; width: 100%;`};
  div {
    width: 95%;
    margin: 0 auto;
    ${media.md.below`width: 90%;`};
  }
  iframe {
    padding: 0;
    background: #000;
    margin: 0;
    width: 70%;
    position: relative;
    aspect-ratio: 16/9;
    flex-grow: 1;
    border-radius: 16px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.6);

    ${media.md.below`width: 100%; box-shadow:none;`};
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100px;

    width: 100%;
    height: 65%;

    background: ${getColor('green')};

    transform: skewY(-3deg);
    z-index: -1;
    ${media.md.below`height: 90%;`};
  }
`;

const Callout = styled.p<UIText.CalloutProps>`
  font-size: ${rem(24)};
  font-weight: ${fonts.weight.regular};
  font-family: ${fonts.primaryFont};
  line-height: 1.4;
  color: ${getColor('black')};
  padding-top: 0px;
  margin-top: 0px;

  ${media.sm.below`font-size: 20px;`};

  b,
  strong {
    font-weight: ${fonts.weight.semibold};
  }

  i,
  em {
    font-style: italic;
  }
`;

export const CenteredHero = ({
  title,
  preTitle,
  description,
  illustration,
  animate,
  primaryButtonText,
  primaryButtonLink,
  secondaryButtonText,
  secondaryButtonLink,
  videoUrl,
}: DataGraphcms.BlockHero) => {
  const primaryLink = getLink(primaryButtonLink);
  const primaryTarget =
    primaryLink?.indexOf('http') === 0 ? '_blank' : undefined;

  const hasPrimary = primaryLink && primaryButtonText;

  const secondaryLink = getLink(secondaryButtonLink);
  const secondaryTarget =
    secondaryLink?.indexOf('http') === 0 ? '_blank' : undefined;

  const hasSecondary = secondaryLink && secondaryButtonText;

  const showVideo = videoUrl?.length;
  const showIllustration = illustration?.length && !showVideo;

  return (
    <StyledHero>
      {title && (
        <Title>
          {preTitle && <div>{preTitle}</div>}
          {title}
        </Title>
      )}
      {(description || hasPrimary || hasSecondary) && (
        <Description>
          {description && <Callout>{description}</Callout>}
          {(hasPrimary || hasSecondary) && (
            <>
              {hasPrimary && (
                <KlappirButton
                  target={primaryTarget}
                  variant="primary"
                  icon={'arrow-right'}
                  label={primaryButtonText}
                  href={primaryLink}
                />
              )}
              {hasSecondary && (
                <KlappirButton
                  variant="primary"
                  icon="arrow-right"
                  label={secondaryButtonText}
                  href={secondaryLink}
                  target={secondaryTarget}
                />
              )}
            </>
          )}
        </Description>
      )}

      {showVideo && (
        <VideoWrapper>
          {/*
            Vimeo video player in an iframe. Vimeo video url comes from hygraph and should incldue the numerical video id. We get that ID and plug it into the vimeo player url in the iframe src url. Color player option is also added as a query param in the src url. At the time of writing the color option was not editable on vimeo.com embed appearance controls. Appearance controls for embedded vimeo videoes can be found and configured on vimeos platform.
            */}
          <div>
            <iframe
              title="Klappir sustainability platform"
              src={videoUrl}
              allowFullScreen={true}
            ></iframe>
          </div>
        </VideoWrapper>
      )}
      {showIllustration && (
        <SceneIllustration animate={animate} id={illustration} />
      )}
    </StyledHero>
  );
};
