import { DataGraphcms } from '@klappir/types';
import { getColor } from '@klappir/ui/brand';
import { Column, Row } from '@klappir/ui/core';
import {} from '@klappir/ui/illustration';
import { H2, H4 } from '@klappir/ui/text';
import { media } from '@klappir/util/styles';
import styled from '@klappir/vendor/styled';

import { VideoContainer } from '../Components/VideoContainer';
import { splitTitle } from '../Utils/headingHelpers';

const BlockHeadingTextContentAndVideoSection = styled.section`
  width: 100%;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  ${media.xl.below`padding-top:50px; padding-bottom:50px;`};
`;

const Title = styled(H2).attrs({ as: 'h2' })`
  width: 60%;
  padding: 80px 0 50px 0;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  ${media.sm.below`width:90%;`};
`;

const TextWrapper = styled.div`
  padding: 64px;

  @media (max-width: 600px) {
    padding: 16px;
  }
  display: grid;
  line-height: 1.5;
  height: 100%;
  justify-content: center;
  align-content: center;
  justify-items: start;
  width: 80%;
  margin: 0 auto;

  ${H4} {
    width: 100%;
  }
`;

export const BlockHeadingTextContentAndVideo = ({
  heading: { title, coloredPart },
  textContentTitle,
  textContent,
  videoUrl,
}: DataGraphcms.BlockHeadingTextContentAndVideo) => {
  const headingParts = splitTitle(title, coloredPart);

  return (
    <BlockHeadingTextContentAndVideoSection>
      <>
        {title && (
          <Title>
            {headingParts.map((part, index) =>
              part.toLowerCase() === coloredPart.toLowerCase() ? (
                <span key={index} style={{ color: getColor('green') }}>
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </Title>
        )}

        <Row>
          <Column tiny={12} md={10} big={5} huge={4}>
            <TextWrapper>
              {textContentTitle && <H4 bold>{textContentTitle}</H4>}
              {textContent && <p>{textContent}</p>}
            </TextWrapper>
          </Column>
          {videoUrl && (
            <Column tiny={12} md={10} big={6} huge={5}>
              <VideoContainer
                videoUrl={videoUrl}
                title={'Klappir sustainability platform'}
                background={{
                  type: 'leftAndRight',
                  leftColor: '#e88358',
                  rightColor: '#e8ac58',
                }}
              />
            </Column>
          )}
        </Row>
      </>
    </BlockHeadingTextContentAndVideoSection>
  );
};
